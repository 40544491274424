import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import {
  withStyles,
  Grid,
  Button,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { getAssessmentParts } from 'efqm-theme/assessments/getAssessmentParts'
import {
  AuthContext,
  getChartData,
  BarChartTable,
  ConfirmDialog,
  PaddedContainer,
  SectionTitle,
  RichText,
} from 'gatsby-components'
import { Link } from 'gatsby'
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded'
import { useMutation } from 'graphql-hooks'
import get from 'lodash/get'
import classnames from 'classnames'

import FeedbackReportInput from '../components/FeedbackReportInput'
import SEO from '../components/SEO'
import { getAssessmentId } from '../utils/url'
import { filterOldScores } from '../utils/filter-old-scores'
import FileItem from '../components/FileItem'
import FileList from '../components/FileList'

import {
  getAssessmentFeedbackReportData,
  updateAssessmentPillarSummaryMutation,
  updateAssessmentAdviceMutation,
  closeAssessmentMutation,
} from '../queries'
import useAuthorizedWatch from '../hooks/useAuthorizedWatch'

function sortByPart(obj, key) {
  if (!obj[key]) return null

  return obj[key]
    .sort((a, b) => a.part_number - b.part_number)
    .reduce(
      (acc, item) => [
        ...acc,
        ...item.feedback_values.map(value => ({
          value: value[key],
          link: `${item.pillar_key}/${item.criterion_key}/${item.part_number}`,
        })),
      ],
      []
    )
}

function FeedbackReport({
  theme,
  classes,
  pageContext: { assessment: contextAssessment, pillarColors },
  location,
}) {
  const { t, i18n } = useTranslation()
  const { assessment } = getAssessmentParts(contextAssessment.key, i18n)
  const assessmentId = getAssessmentId(location)
  const [closeAssessment] = useMutation(closeAssessmentMutation)

  const { getUserTokenData } = useContext(AuthContext)
  const {
    isEfqmAdmin,
    isPartnerAdmin,
    isAdmin,
    isOrgAdmin,
    isAssessor,
    userId,
  } = getUserTokenData(assessmentId)

  const {
    data: assessmentData,
    fetchedTimestamp,
    refetch: fetchAssessmentFeedbackData,
  } = useAuthorizedWatch(
    getAssessmentFeedbackReportData,
    { assessmentId },
    {
      onPreFetch: variables => !!variables.assessmentId,
      onFetch: data => filterOldScores(assessment, data.assessment_by_pk),
    }
  )

  let canSubmit = isEfqmAdmin || isPartnerAdmin || isOrgAdmin || isAdmin
  // if (get(assessmentData, 'internal')) {
  //   // For Internal Assessment, this should be done by the Org Admin or Group Admin
  //   // from the organisation/group who has done the assessment
  //   // TODO: add support for multƒi-level
  //   canSubmit =
  //     isEfqmMember ||
  //     ((isAdmin || isOrgAdmin) && userHasGroup(assessmentData.group.id))
  // } else {
  //   // For External Assessment, this should only be possible for the EFQM Admin person
  //   // or a user from an organisation set as Partner, with a profile as org admin
  //   canSubmit = isEfqmMember || (isOrgAdmin && isPartner)
  // }
  const handleSubmitFeedbackReport = async () => {
    await closeAssessment({
      variables: {
        id: assessmentId,
        updatedAt: new Date(),
      },
    })

    navigate('/')
  }

  const canEditSummaryAndAdvice = isAdmin && isAssessor
  const canManageFiles = isAssessor || isEfqmAdmin || isPartnerAdmin

  const chartData = getChartData(assessment, assessmentData, pillarColors)

  const assessmentName = get(assessmentData, 'name', t('Loading...'))

  const filesUploadedByAssessor = get(assessmentData, 'files', [])

  return (
    <div className={classes.root}>
      <SEO title={t(assessment.name)} />
      <PaddedContainer className={classes.paddedContainer}>
        <Button
          component={Link}
          to={`/assessment/${assessment.key}#${assessmentId}`}
          variant="text"
          color="secondary"
        >
          ◀ {t('Assessment overview')}
        </Button>
        <div className={classes.section}>
          <Grid container>
            <Grid item>
              <Typography variant="h4" gutterBottom>
                {t('feedback report')}
              </Typography>
              <Typography variant="h2" color="primary">
                {assessmentName}
              </Typography>
            </Grid>
            <Grid item xs />
            <Grid item className={classes.hideOnPrint}>
              <Button
                color="secondary"
                variant="outlined"
                className={classes.printButton}
                onClick={() => window.print()}
              >
                {t('Print Report')}
              </Button>
              {canSubmit && (
                <ConfirmDialog
                  disabled={!assessmentData}
                  onConfirm={handleSubmitFeedbackReport}
                  title={t('Submit report for', { fileName: assessmentName })}
                  text={t(
                    `The feedback report for this assessment will be finalised. No more edits or scoring will be possible. This cannot be undone.`
                  )}
                >
                  <Button color="secondary" variant="contained">
                    {t('Submit Feedback Report')}
                  </Button>
                </ConfirmDialog>
              )}
            </Grid>
          </Grid>
        </div>
        <div className={classes.section}>
          <Grid container spacing={0}>
            <Grid container item spacing={3} className={classes.hideOnPrint}>
              <Grid item xs={3}>
                <SectionTitle
                  barColor={theme.palette.primary.dark}
                  gutterBottom
                >
                  {t(`Assessment scoring summary`)}
                </SectionTitle>
              </Grid>
              <Grid item xs={3}>
                <SectionTitle
                  barColor={theme.palette.primary.dark}
                  gutterBottom
                >
                  {t(`Assessment scoring summary`)}
                </SectionTitle>
              </Grid>
              <Grid item xs={6} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              spacing={2}
              className={classes.assessmentDataGrid}
            >
              <Grid
                item
                container
                xs={3}
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                className={classes.hideOnPrint}
              >
                {canManageFiles && (
                  <Grid item className={classes.assessmentDocumentsGrid}>
                    <SectionTitle
                      barColor={theme.palette.primary.dark}
                      gutterBottom
                      className={classes.assessmentDocumentsHeader}
                    >
                      {t(`Assessment Documents`)}
                    </SectionTitle>
                    {assessment.feedbackReportTemplateFiles.map(
                      ({ fileUrl, fileName }) => (
                        <FileItem
                          file={{
                            fileUrl,
                            file_name: fileName,
                          }}
                          key={fileName}
                          isGuidanceFile
                        />
                      )
                    )}
                  </Grid>
                )}
                {assessment.matrixType !== 'basic' && (
                  <Grid item className={classes.supportingDocuments}>
                    {(canManageFiles ||
                      (!canManageFiles &&
                        !!filesUploadedByAssessor.length)) && (
                      <>
                        <SectionTitle barColor={theme.palette.primary.dark}>
                          {t(`Supporting Documents`)}
                        </SectionTitle>
                        <div className={classes.fileList}>
                          <FileList
                            assessmentId={assessmentId}
                            userId={userId}
                            files={filesUploadedByAssessor}
                            canUpload={canManageFiles}
                            canDelete={canManageFiles}
                            onUploadComplete={fetchAssessmentFeedbackData}
                            alignItems="flex-start"
                            hideHeader
                            isFeedbackReportFile
                          />
                        </div>
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid item xs>
                {assessmentData && (
                  <BarChartTable
                    chartData={chartData}
                    assessmentId={assessmentId}
                    canExpand={assessmentData.key !== 'efqm-2020'}
                    bottomText={t(
                      `Mouse over the criteria bar on the chart to see the sub-criteria scoring.`
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.section}>
          {assessmentData &&
            assessment.pillars.map((pillarDef, pillarIndex) => {
              return (
                <React.Fragment key={pillarDef.key}>
                  <div className="page-break" />
                  <Grid container direction="column">
                    <Grid
                      item
                      xs={12}
                      className={classnames(
                        classes.input,
                        classes.inputChipGrid
                      )}
                    >
                      {assessmentData && (
                        <FeedbackReportInput
                          label={t(pillarDef.name)}
                          name="pillar_summary"
                          assessmentId={assessmentId}
                          initialValue={
                            assessmentData.pillar_summary !== null
                              ? assessmentData.pillar_summary
                              : {}
                          }
                          mutation={updateAssessmentPillarSummaryMutation}
                          canEdit={canEditSummaryAndAdvice}
                          pillarColor={pillarColors[pillarIndex]}
                          rows={7}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {pillarDef.criteria.map((criterionDef, criterionIndex) => {
                    const feedbackByTableKey = assessmentData.feedbackTables.reduce(
                      (acc, table) => {
                        if (
                          table.criterion_key === criterionDef.key &&
                          table.pillar_key === pillarDef.key
                        ) {
                          acc[table.table_key] = (
                            acc[table.table_key] || []
                          ).concat(table)
                        }
                        return acc
                      },
                      {}
                    )

                    const feedbackAreas = [
                      ['Strength', sortByPart(feedbackByTableKey, 'strengths')],
                      [
                        'Areas of improvement',
                        sortByPart(feedbackByTableKey, 'areas-of-improvement'),
                      ],
                    ]

                    return (
                      <React.Fragment key={criterionDef.key}>
                        {!!criterionIndex && <div className="page-break" />}
                        <Box width={1 / 3}>
                          <SectionTitle barColor={pillarColors[pillarIndex]}>
                            <Link
                              to={`/assessment/${assessmentData.key}/${pillarDef.key}/${criterionDef.key}/1/#${assessmentId}`}
                            >
                              {t(criterionDef.name)}
                            </Link>
                          </SectionTitle>
                        </Box>
                        {feedbackAreas.map(area => {
                          const [label, feedbackItems] = area
                          if (!feedbackItems) return null

                          const key = `${pillarDef.key}-${criterionDef.key}-${label}`
                          return (
                            <Paper key={key}>
                              {feedbackItems.map((feedbackItem, i) => (
                                <Table
                                  size="small"
                                  className={classes.stopPageBreakWithin}
                                  key={`${key}-${i}`}
                                >
                                  {!i && (
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>{t(label)}</TableCell>
                                        <TableCell align="right" />
                                      </TableRow>
                                    </TableHead>
                                  )}
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>
                                        <RichText value={feedbackItem.value} />
                                      </TableCell>
                                      <TableCell align="right">
                                        <Link
                                          to={`/assessment/${assessmentData.key}/${feedbackItem.link}/#${assessmentId}`}
                                        >
                                          <IconButton>
                                            <ChevronRightIcon />
                                          </IconButton>
                                        </Link>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              ))}
                            </Paper>
                          )
                        })}
                      </React.Fragment>
                    )
                  })}
                </React.Fragment>
              )
            })}
        </div>
        <div className={classes.section}>
          {assessmentData && (
            <div className={classes.inputChipGrid}>
              <FeedbackReportInput
                label={t('Advice for Company')}
                name="advice"
                assessmentId={assessmentId}
                initialValue={assessmentData.advice || ''}
                dataFetchedTimestamp={fetchedTimestamp}
                mutation={updateAssessmentAdviceMutation}
                canEdit={canEditSummaryAndAdvice}
                rows={6}
              />
            </div>
          )}
        </div>
      </PaddedContainer>
    </div>
  )
}

const styles = theme => ({
  root: {
    '@media screen': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  paddedContainer: {
    '@media screen': {
      flex: 1,
    },
    '@media print': {
      padding: 0,
    },
  },
  section: {
    marginTop: theme.spacing(3),
    '& > *': {
      margin: theme.spacing(4, 0),
    },
  },
  printButton: {
    marginRight: theme.spacing(2),
    minWidth: '167px',
  },
  assessmentDataGrid: {
    marginTop: theme.spacing(0.5),
  },
  assessmentDocumentsGrid: {
    marginRight: '2px',
  },
  assessmentDocumentsHeader: {
    marginBottom: theme.spacing(2),
  },
  supportingDocuments: {
    width: 'calc(100% - 2px)',
    marginTop: theme.spacing(3),
  },
  templateFilesHeader: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  fileList: {
    '& > * > * > * > .MuiButton-outlinedSecondary': {
      [theme.breakpoints.up('md')]: {
        width: '167px',
      },
    },
  },
  input: {
    marginTop: theme.spacing(4),
  },
  inputChipGrid: {
    '& > form > div:nth-child(2)': {
      '@media print': {
        display: 'none',
      },
    },
  },
  feedbackArea: {
    marginTop: theme.spacing(2),
  },
  hideOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
  stopPageBreakWithin: {
    '@media print': {
      breakInside: 'avoid',
    },
  },
  '@media print': {
    '@global': {
      footer: {
        display: 'none',
      },
      '.page-break': { display: 'block', pageBreakBefore: 'always' },
    },
  },
  '@media all': {
    '.page-break': { display: 'none' },
  },
})

export default withStyles(styles, { withTheme: true })(FeedbackReport)
export { sortByPart }
